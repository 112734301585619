import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Theme,
  CardHeader,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pencil from '../icons/pencil';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => {
  return {
    cardGrid: {
      minWidth: '17rem',
    },
    card: {
      backgroundColor: theme.palette.primary.dark,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
});

interface DetailCardProps {
  name: string;
  id: string;
  pickup: string;
  otherStops?: string[];
  dropoff: string;
  time: string;
  description: string;
  onEdit?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isComplete: boolean;
  onComplete?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
}

const DetailCard: React.FC<DetailCardProps> = ({
  name,
  id,
  onEdit,
  isComplete,
  time,
  pickup,
  otherStops,
  dropoff,
  description,
  onComplete,
}) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = React.useState(false);
  const submit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setSubmitting(true);
    if (onComplete) {
      onComplete(event).finally(() => {
        setSubmitting(false);
      });
    }
  };

  return (
    <Grid item md={6} xs={12} className={classes.cardGrid}>
      <Card className={classes.card}>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography variant="h6" component="h6">
                {moment(time).calendar(null, {
                  sameDay: '[Today] HH:mm',
                  nextDay: '[Tomorrow] HH:mm',
                  nextWeek: 'dddd HH:mm',
                  lastDay: '[Yesterday] HH:mm',
                  lastWeek: '[Last] dddd HH:mm',
                  sameElse: 'DD/MM/YYYY',
                })}
              </Typography>
              <Typography variant="h6" component="h6">
                {id}
              </Typography>
            </Grid>
          }
        />
        <CardContent>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Typography component="p" gutterBottom>
                Pickup: {pickup}
              </Typography>
              {otherStops && otherStops.length > 0 && (
                <Typography component="p" gutterBottom>
                  Stopping at: {otherStops.join(', ')}
                </Typography>
              )}
              <Typography component="p" gutterBottom>
                Dropoff: {dropoff}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="p" gutterBottom align="right">
                {name}
              </Typography>
              <Typography component="p" gutterBottom align="right">
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onEdit}
                disabled={submitting}
              >
                <Pencil />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                disabled={!isComplete || submitting}
                color="secondary"
                onClick={submit}
              >
                {submitting ? (
                  <>
                    <CircularProgress />
                    <Typography variant="srOnly">SUBMITTING</Typography>
                  </>
                ) : (
                  <>Complete Trip</>
                )}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default DetailCard;
