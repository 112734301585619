import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import FooterButton from '../components/footerButton';
import DetailCardList from '../components/detailCardList';
import { Interaction } from '../services/interactions';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import { BaseProps } from '../typings/types';
import { Snackbar, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTripsContext } from '../components/tripsContext';
import { useFormContext } from '../components/formContext';

interface PureActiveTripsProps {
  trips?: Interaction[];
  onComplete: (id: string) => () => Promise<void>;
}

export const PureActiveTrips: React.FC<PureActiveTripsProps> = ({
  trips,
  onComplete,
}) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const handleOnComplete = (id: string): ReturnType<typeof onComplete> => {
    const completePromise = onComplete(id);
    return async (): Promise<void> => {
      await completePromise().then(() => {
        setOpenSnackbar(true);
      });
    };
  };
  const handleClose = (
    _: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  return (
    <Layout noFooter={!trips} wrapper={false} title="Active trips">
      {trips ? (
        <DetailCardList data={trips} onComplete={handleOnComplete} />
      ) : (
        ''
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'complete-message',
        }}
        message={
          <span id="complete-message">Transport submitted. Thank you.</span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>,
        ]}
      />
      <FooterButton
        color="secondary"
        onClick={(): void => {
          navigate('/new-trip');
        }}
      >
        NEW TRIP
      </FooterButton>
    </Layout>
  );
};

const ActiveTrips: React.FC<BaseProps> = ({ location: { pathname } }) => {
  useAuthRouteProtection(pathname);
  const { clearForm } = useFormContext();
  useEffect(() => {
    // Don't let stale form data survive
    clearForm();
  }, []);
  const { trips, completeTrip } = useTripsContext();
  return (
    <PureActiveTrips
      trips={trips.length ? trips : undefined}
      onComplete={completeTrip}
    />
  );
};

export default ActiveTrips;
