import React from 'react';
import { navigate } from 'gatsby';
import { Grid } from '@material-ui/core';
import DetailCard from './detailCard';
import { Interaction } from '../services/interactions';
import { tripSchema } from '../services/trips';
import { tallyTotal } from './tallyTracker';

interface DetailCardListProps {
  data?: Interaction[];
  onComplete: (id: string) => () => Promise<void>;
}

const DetailCardList: React.FC<DetailCardListProps> = ({
  data,
  onComplete,
}) => {
  return (
    <Grid
      direction="row"
      alignItems="center"
      justify="space-around"
      container
      spacing={2}
    >
      {data &&
        data.map((trip: Interaction) => {
          const isComplete = tripSchema.isValidSync(trip);
          const {
            id,
            dropOffLocation,
            name,
            timeOccurred,
            pickupLocation,
            otherStops,
            tally,
          } = trip;
          return (
            <DetailCard
              key={id}
              time={timeOccurred ? timeOccurred : ''}
              name={name ? name : 'Un-named trip'}
              description={`Group of ${tallyTotal(tally)}`}
              pickup={pickupLocation ? pickupLocation : 'Not Stated'}
              dropoff={dropOffLocation ? dropOffLocation : 'Not Stated'}
              otherStops={otherStops}
              id={id.toUpperCase()}
              onEdit={(): void => {
                navigate('/new-trip', { state: { id } });
              }}
              isComplete={isComplete}
              onComplete={onComplete(id)}
            />
          );
        })}
    </Grid>
  );
};

export default DetailCardList;
